import React from 'react';
import './App.css';
import p from '../src/p.png';

function App() {
  return (
    <div className="App">
      <img src={p} alt="Update" />
      <h2>We'll be back.</h2>
      <p>We're busy updating Ever-Yog for you...</p>
    </div>
  );
}

export default App;
